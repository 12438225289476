<template>

	<div id="team" class="group" ref="group" :class="{ screenshot : screenshot }">

		<div class="screenshot-top" v-if="screenshot">

			<img src="@/assets/img/logo-random-ttfl.png" />

			<div>

				<div class="title">
					<div>Night <em>#</em>{{ pick_number }}</div>
					<div class="black">Deck <em>#</em>{{ deck_number }}</div>
				</div>

				<div>
					
				</div>

			</div>

		</div>

		<container
			ref="group_container"
			:collapse_on_mount="true"
			:header_toggle="false"
			:screenshot_function="take_screenshot"
			:screenshot="screenshot"
			:tweet_content="tweet_content"
		>

			<template slot="container-head">

				<div v-if="!screenshot">
					<em :class="{ red : ( picks_count < 10 ) }">{{ picks_count }} / 10</em>
				</div>

				<div>

					<span v-if="team_score > 0">
						Score : <em class="red">{{ team_score }} PTS ({{ team_est_vs_score }})</em>
					</span>
					<span v-else>
						Estimation : <em class="red">{{ team_estimation }} PTS</em>
					</span>

				</div>

				<div v-if="!screenshot && !is_phone()">

					<vselect
						v-if="users_option.length > 0"
						ref="user_select"
						id="user-select"
						:options="users_option"
						:select="current_user_id"
						:onchange="change_user"
						:placeholder="'Je suis...'"
					/>

				</div>

			</template>

			<template slot="container-body"><div class="picks-list flex-display">

				<div
					v-for="( user, key ) in sorted_users"
					v-bind:key="key"
					class="pick"
					:class="{
						picked : ( user.pick ),
						injured	: ( user.pick && user.pick.injury && user.pick.injury != 'healthy' )
					}"
				>

					<div>

						<div class="pick-pseudo">{{ user.user.pseudo }}</div>

						<div class="score" v-if="( day_is_passed && user.pick && user.pick.score )">
							<span :style="'opacity:' + user.pick.score.opacity" :class="'ttfl-score_bg ' + user.pick.score.class"></span>
							<span class="ttfl-score">{{ user.pick.score.value }}</span>
						</div>

						<div class="score" v-if="( !day_is_passed && user.pick && user.pick.estimation )">
							<span :style="'opacity:' + user.pick.estimation.opacity" :class="'ttfl-score_bg ' + user.pick.estimation.class"></span>
							<span class="ttfl-score">{{ user.pick.estimation.value }}</span>
						</div>

					</div>

					<div>

						<div v-if="user.pick && user.player">
							<div class="player-fullname">{{ user.player.vue.fullname }}</div>
							<span>vs</span> <span><img class="team-logo" :src="require( '@/assets/img/logos/' + user.player.vue.team_vs_logo )"></span>
						</div>

					</div>

				</div>

			</div></template>

		</container>

	</div>

</template>

<script>

	import api from '@/api'
	import Tools from '@/class/Tools.class'
	import Deck from '@/class/Deck.class'

	import mixins_global from '@/mixins/global'
	import mixins_container from '@/mixins/container'
	import device from '@/mixins/device'

	export default {
		
		name	: 'team',

		components	: {
	
			container	: () => import( '@/components/container' ),
			vselect	: () => import( '@/components/form/vselect' ),
			
		},

		props	: [ 'day' ],

		data	: function() { return {

			ready	: false,

			users	: [],
			picks_count	: 0,
			team_estimation	: 0,
			team_score	: 0,
			pick_number	: 1,
			deck_number	: 1,
			day_is_passed	: false,

			screenshot	: false,
			// screenshot: true,

		}},

		mixins	: [ mixins_global, mixins_container, device ],

		methods	: {

			/**
			 */

			set_picks	: function() {

				if ( this.ready < 2 )	return false

				var _this	= this

				api.get( 'ttfl_team/picks', {

					'date'	: this.$parent.day.format( 'YYYY-MM-DD' ),
					
				}).then(( json ) => {

					if ( !json )	return

					_this.users	= []
					_this.picks_count	= 0

					_this.$store.state.team.users.forEach(function( user ) {

						var tmp	= {}

						tmp.user	= user
						tmp.pick	= json.find( pick => pick.user_id === user.id )

						if ( tmp.pick ) {

							tmp.player	= _this.$store.getters['players/get_player']( tmp.pick.player_id )

							if ( tmp.player ) {
								tmp.opp_team	= Tools.search_team( tmp.player.team_opp_id )
							} else {
								tmp.opp_team	= false
							}

							if ( tmp.pick.score ) {
								tmp.pick.score	= Tools.ttfl_score_color( tmp.pick.score, 70, 30 )
							} else {
								tmp.pick.score	= Tools.ttfl_score_color( 0, 70, 30 )
							}

							if ( tmp.pick.estimation ) {
								tmp.pick.estimation	= Tools.ttfl_score_color( tmp.pick.estimation, 70, 30 )
							} else {
								tmp.pick.estimation	= Tools.ttfl_score_color( 0, 70, 30 )
							}

							var injury	= _this.$store.state.players.injuries.find( ( inj_player => inj_player.player_id == tmp.pick.player_id ) )

							if ( injury ) {
								tmp.pick.injury	= injury.formated
							}

							_this.picks_count++

						}

						_this.users.push( tmp )
						_this.set_estimation()
						_this.set_score()

					})

				})

			},

			/**
			 */

			set_estimation	: function() {
				
				var _this	= this
				
				this.team_estimation	= 0

				this.users.forEach(function( user ) {

					if ( user.pick ) {
						
						var player	= _this.$store.getters['players/get_player']( user.pick.player_id )

						if ( player ) {
							_this.team_estimation	+= player.ttfl.estimation.value
						}

					}

				})

			},

			/**
			 */

			set_score	: function() {
				
				var _this	= this
				
				this.team_score	= 0

				this.users.forEach(function( user ) {

					if ( user.pick ) {

						if ( user.pick && user.pick.score.value ) {
							_this.team_score	+= user.pick.score.value
						}

					}

				})

			},

		},

		watch	: {

			/**
			 */

			store_players() {

				if ( !this.is_phone() && !this.screenshot && this.$store.state.user.id && this.$refs.user_select ) {

					if ( !this.$refs.user_select.selected_option ) {
						this.$refs.user_select.manual_select_option( this.$store.state.user.id, true )
					}

				}

				this.ready++
				this.set_picks()

			},

			/**
			 */

			user_store_day_pick() {

				this.ready++
				this.set_picks()

			}

		},

		computed	: {

			/**
			 * Tableau original des joueurs
			 * 
			 * Permet la surveillance des données joueurs afin de mettre à jour les picks
			 */

			store_players() {
				return this.$store.state.players.originals
			},

			/**
			 * Tableau original des membres
			 */

			store_users() {
				return this.$store.state.team.users
			},

			/**
			 * Récupération des options pour la select utilisateur
			 */

			users_option() {

				var users	= this.$store.state.team.users
				var options	= []

				users.forEach(function( user ) {
					options.push({
						label	: user.pseudo,
						value	: user.id,
					})
				})

				return options

			},

			/**
			 * Membre actuel sélectionné
			 */

			get_user() {
				return this.$store.state.user
			},

			/**
			 * Membre actuel sélectionné
			 */

			user_store_day_pick() {
				return this.$store.state.user.picks.find( pick => pick.date == this.day.format( 'YYYY-MM-DD' ) )
			},

			/**
			 * ID Membre actuel
			 */

			user_id() {
				return ( this.$store.state.user && this.$store.state.user.id ) ? this.$store.state.user.id : 'null'
			},

			/**
			 */

			sorted_users() {

				var _this	= this

				var tmp	= this.users.slice().sort( ( a , b ) => {

					var a_val	= 0
					var b_val	= 0

					/**
					 * Classement par ranking ASC
					 */

					if ( a.user.rankings.length > 0 && b.user.rankings.length > 0 ) {

						a_val	= a.user.rankings[ a.user.rankings.length - 1 ].value * -1
						b_val	= b.user.rankings[ a.user.rankings.length - 1 ].value * -1

					}

					if ( a_val < b_val ) return 1
					if ( a_val > b_val ) return -1

					return 0

				})

				/**
				 * Classement par ranking ASC
				 */

				if ( _this.day_is_passed ) {

					tmp	= tmp.slice().sort( ( a , b ) => {

						var a_val	= 0
						var b_val	= 0

						if ( a.pick && a.pick.score && b.pick && b.pick.score ) {

							a_val	= a.pick.score.value
							b_val	= b.pick.score.value

						}

						if ( a_val < b_val ) return 1
						if ( a_val > b_val ) return -1

						return 0

					})

				}

				return tmp

			},

			/**
			 */

			team_est_vs_score	: function() {

				var diff	= this.team_score - this.team_estimation

				if ( diff > 0 ) {
					diff	= '+' + diff
				}

				return diff
				
			},

			/**
			 */

			tweet_content	: function() {

				var text	= "#TTFL Night #" + this.pick_number + " - Deck #" + this.deck_number + "\n"

				text	+= "Estimation : " + this.team_estimation + " PTS\n\n"
				text	+= "TEXT \n\n"
				text	+= "#LetsGoRandomTTFL"

				return text

			},

		},

		mounted	: function() {

			var _this	= this
			var day_before	= this.$moment( this.day.valueOf() )

			this.set_picks()

			api.get( 'day/pick_number', {

				date	: day_before.format( 'YYYY-MM-DD' ),

			}).then(( datas ) => {

				_this.pick_number	= datas.pick_number

			})

			var deck	= new Deck( this.day )
			this.deck_number	= deck.number

			/** Afin d'avoir les estimations le plus tard possible */
			var matchs_end	= this.$moment( day_before.format( 'YYYY-MM-DD' ) + ' 23:59:59' ).add( 8, 'hours' )

			this.day_is_passed	= ( matchs_end.valueOf() < this.$moment().valueOf() ) ? true : false

			this.screenshot_filename	= 'Night picks - Random team'
			
		},

	}

</script>

<style lang="scss" scoped>

	@import '@/assets/scss/_base';

	/** ---------------------------------------*\
	*---------------------------------------- **/

	#team::v-deep {
		
		.container header.container-head {

			@include mobile() {
				font-size: 0.8em;
			}

			@include iphone5() {
				font-size: 0.75em;
			}
			
			& > div.head-actions {

				// max-width: 105px;

				button {

					@include mobile() {
						margin-left: 10px;
					}

				}

			}

			& > div:not(.head-actions) > div {

				margin-right: 25px;

				@include mobile() {
					margin-right: 15px;
				}

			}

			.vselect {

				margin-left: 15px;

				@include iphone5() {
					margin-left: 10px;
				}

			}

			em {

				font-style: normal;

				&.red {
					color: $red;
				}

			}

			.btn-screenshot button {
				padding: 0;
			}

		}

		.container-body {

			padding: 0;

		}

	}

	/** ---------------------------------------*\
	*---------------------------------------- **/

	.picks-list {

		align-items: stretch;

		@include mobile() {
			flex-flow: row wrap;
		}

	}

	/** ---------------------------------------*\
	*---------------------------------------- **/

	.pick {

		flex: 10%;
		max-width: 10%;
		text-align: center;
		border-left: 2px solid #000;

		@include mobile() {
			flex: calc( 100% / 2 );
			max-width: calc( 100% / 2 );
			border: 0;
			border-top: 2px solid #000;
		}

		&:nth-child(odd) {

			@include mobile() {
				border-left: 0;
				border-right: 2px solid #000;
			}

		}

		&:first-child {
			border-left: 0;
		}

		&:nth-child(1),
		&:nth-child(2) {
			
			@include mobile() {
				border-top: 0;
			}

		}

		&:not(.picked) > div {
			opacity: 0.4;
		}

		&.injured {
			background: lighten( $orange, 25% );
		}

		& > div {
			padding: 7.5px 15px;
			text-transform: uppercase;
		}

		& > div:nth-child(1) {

			display: flex;
			justify-content: space-between;
			align-items: stretch;
			height: 30px;
			padding: 0;
			line-height: 30px;
			border-bottom: 2px solid #000;

			& > div {
				flex: auto;
				padding: 0 5px;
				line-height: 30px;
			}

			div:not(.score) {
				font-size: 0.65em;
			}

			.score {
				flex: 40px;
				max-width: 40px;
				border-left: 2px solid #000;
				font-size: 0.8em;
			}

		}

		& > div:nth-child(2) {

			font-size: 0.6em;
			line-height: 1.5em;

			div.player-fullname {
				margin-bottom: 5px;
			}

			span {
				display: inline-block;
				vertical-align: middle;
				margin: 0 2.5px;
			}

			.team-logo {

				display: inline-block;
				vertical-align: middle;
				width: 30px;
				height: auto;

			}

		}

		.pick-pseudo {
			background: transparentize( $blue, 0.7 );
		}

	}

	/** ---------------------------------------*\
	*---------------------------------------- **/

	#team.screenshot::v-deep {

		width: 900px;

		.container > div {
			border: 0;
		}

		.container-head {
			border: 4px solid #000;
			border-bottom: 0;
		}

		.container-body {
			border: 2px solid #000;
			border-left-width: 4px;
		}

		.picks-list {
			flex-flow: row wrap;
		}

		.picks-list .pick {
			flex: calc( 100% / 5 );
			max-width: calc( 100% / 5 );
			border: 2px solid #000;
			border-top: 0;
			border-left: 0;
		}

	}

</style>
